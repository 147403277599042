import {cloneDeep, cssVal, getDataByCodePostal, hidden, newInput, tryToDate, visible} from "@/data/helpers";
import {def} from "@/data/dictionnaire";

export const sas = {
    title: "SAS Société par actions simplifiée",
    texte: "{{statusGroup}} a été constituée la société suivante : <strong>Forme</strong> : {{societyGroup.societeFormeJuridique}} <strong>Dénomination : {{societyGroup.societeDenomination}}</strong>[[ <strong>Sigle</strong> : {{societyGroup.sigle}}]]. <strong>Siège</strong> : {{siegeSocialGroup.societeAdresse}} [[{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}. <strong>Objet</strong> : {{societyGroup.objet}}. <strong>Durée</strong> : {{societyGroup.duree}} ans à compter de <strong>l'immatriculation au Rcs</strong> {{siegeSocialGroup.societeRcsVille}}. <strong>Capital</strong> : {{societyGroup.typeCapital}}. <strong>Président</strong> : {{presidentGroup.nom}} {{presidentGroup.prenom}}, {{presidentGroup.adresse}} {{presidentGroup.adresseSuite}} {{presidentGroup.codePostal}} {{presidentGroup.ville}}. [[<strong>Directeur général</strong> : {{directeurgeneralGroup.nom}} {{directeurgeneralGroup.prenom}}, {{directeurgeneralGroup.adresse}} {{directeurgeneralGroup.adresseSuite}} {{directeurgeneralGroup.codePostal}} {{directeurgeneralGroup.ville}}.]] [[<strong>Commissaires aux comptes : Titulaire</strong> : {{commissaireAuxComptesGroup.indiquez_1}}. [[<strong>Suppléant</strong> : {{commissaireAuxComptesGroup.indiquez_2}}.]]]] <strong>Admission aux assemblées</strong> : {{admissionAssembleGroup.typeAdmission}}. <strong>Cession et transmission des actions</strong> : {{cessionTransmissionActionGroup.typeTransmissionAction}}.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "acteGroup.dateActe":
                tmpText = tryToDate(varText[attribute]);
                break;
            case "societyGroup.typeCapital":
                if(varText['societyGroup.typeCapital'] === 'variable') {
                    tmpText = `variable, initial : ${cssVal(varText["societyGroup.societeCapitalInitial"])} €, minimum : ${cssVal(varText["societyGroup.capitalMinimum"])} €`;
                } else {
                    tmpText = ` ${cssVal(varText["societyGroup.societeCapital"])} €`
                }
                break;
            case "admissionAssembleGroup.typeAdmission":
                if(varText['admissionAssembleGroup.typeAdmission'] !== 'autre')
                    tmpText = ` ${varText['admissionAssembleGroup.typeAdmission']}`;
                else
                    tmpText = ` ${varText['admissionAssembleGroup.autre']}`;
                break;
            case "cessionTransmissionActionGroup.typeTransmissionAction":
                if(varText['cessionTransmissionActionGroup.typeTransmissionAction'] !== 'autre')
                    tmpText = ` ${varText['cessionTransmissionActionGroup.typeTransmissionAction']}`;
                else
                    tmpText = ` ${varText['cessionTransmissionActionGroup.autre']}`;
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        siegeSocialGroup: [{}],
        presidentGroup: [{}],
        directeurgeneralGroup: [{}],
    },
    schema: [
        {
            //0
            name: "acteGroup",
            label: "Société",
            type: "group",
            validation: "required",
            value: [{}],
            children: [
                newInput(def.natureActe, '_required', {
                    on: {
                        change:  (val) => {
                            val = val.target.value;
                            sas.schema[0].children[3] = val === "acte authentique" ? visible(sas.schema[0].children[3]) : hidden(sas.schema[0].children[3]);
                        },
                    },
                }),
                newInput(def.dateActe, '_required'),
                newInput(def.lieuActe, '_required'),
                newInput(def.recuPar, '_required', {}, 'hidden'),
            ],
        },
        {
            //1
            name: "societyGroup",
            label: "La société",
            type: "group",
            value: [{}],
            children: [
                newInput(def.formeSocieteSas, '_required', {name: 'societeFormeJuridique'}),
                newInput(def.denomination, '_required', {name: 'societeDenomination'}),
                newInput(def.sigle),
                newInput(def.objet, '_required'),
                newInput(def.duree, '_required'),
                newInput(def.typeCapital, '_validUniqueSelect', {
                    on: {
                        input: (val) => {
                            sas.schema[1].children[6] = val === "fixe" ? visible(sas.schema[1].children[6]) : hidden(sas.schema[1].children[6]);
                            sas.schema[1].children[7] = val === "variable" ? visible(sas.schema[1].children[7]) : hidden(sas.schema[1].children[7]);
                            sas.schema[1].children[8] = val === "variable" ? visible(sas.schema[1].children[8]) : hidden(sas.schema[1].children[8]);
                        }
                    }
                }),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}),
                newInput(def.capitalInitial, '_required', {name: 'societeCapitalInitial'}, 'hidden'),
                newInput(def.capitalMinimum, '_required', {}, 'hidden'),
            ]
        },
        {
            //2
            name: "siegeSocialGroup",
            label: "Adresse du siège social",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: 'societeAdresse'}),
                newInput(def.adresseSuite, '_required', {name: 'societeAdresse2'}),
                newInput(def.codePostal, '_required', {
                    name: 'societeCodePostal',
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if(val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            sas.schema[2].children[3].options = data.options;
                            sas.model.siegeSocialGroup[0].societeVille = data.ville_slug;
                            sas.model.siegeSocialGroup[0].societeRcsVille = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: 'societeVille'}),
                newInput(def.lieuImmatriculation, '_required', {name: 'societeRcsVille'}, 'disabled'),
            ]
        },
        {
            //3
            name: "presidentGroup",
            label: "Président",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            sas.schema[3].children[5].options = data.options;
                            sas.model.presidentGroup[0].ville = data.ville_slug;

                            sas.model.presidentGroup = cloneDeep(sas.model.presidentGroup);
                        }
                    }
                }),
                newInput(def.ville, '_required')
            ]
        },
        {
            //4
            name: "directeurgeneralGroup",
            label: "Directeur général",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nominationDirecteurGeneral, '', {
                    on: {
                        input: (val) => {
                            sas.schema[4].children[1] = val ? visible(sas.schema[4].children[1]) : hidden(sas.schema[4].children[1]);
                            sas.schema[4].children[2] = val ? visible(sas.schema[4].children[2]) : hidden(sas.schema[4].children[2]);
                            sas.schema[4].children[3] = val ? visible(sas.schema[4].children[3]) : hidden(sas.schema[4].children[3]);
                            sas.schema[4].children[4] = val ? visible(sas.schema[4].children[4]) : hidden(sas.schema[4].children[4]);
                            sas.schema[4].children[5] = val ? visible(sas.schema[4].children[5]) : hidden(sas.schema[4].children[5]);
                            sas.schema[4].children[6] = val ? visible(sas.schema[4].children[6]) : hidden(sas.schema[4].children[6]);
                        }
                    }
                }),
                newInput(def.nom, '_required', {}, 'hidden'),
                newInput(def.prenom, '_required', {}, 'hidden'),
                newInput(def.adresse, '_required', {}, 'hidden'),
                newInput(def.adresseSuite, '', {}, 'hidden'),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            sas.schema[4].children[6].options = data.options;
                            sas.model.directeurgeneralGroup[0].ville = data.ville_slug;

                            sas.model.directeurgeneralGroup = cloneDeep(sas.model.directeurgeneralGroup)
                        }
                    }
                }, 'hidden'),
                newInput(def.ville, '_required', {}, 'hidden')
            ]
        },
        {
            //5
            name: "commissaireAuxComptesGroup",
            label: "Commissaires aux comptes",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nominationCommissaireAuxComptes, '', {
                    on: {
                        input: (val) => {
                            sas.schema[5].children[1] = val ? visible(sas.schema[5].children[1]) : hidden(sas.schema[5].children[1]);
                            sas.schema[5].children[2] = val ? visible(sas.schema[5].children[2]) : hidden(sas.schema[5].children[2]);
                            sas.schema[5].children[3] = val ? visible(sas.schema[5].children[3]) : hidden(sas.schema[5].children[3]);
                            sas.schema[5].children[4] = val ? visible(sas.schema[5].children[4]) : hidden(sas.schema[5].children[4]);
                        }
                    }
                }),
                newInput(def.typeTitulaire, '_required', {
                    on: {
                        input: (val) => {
                            sas.schema[5].children[2].label = val === 'physique' ? 'Indiquez : nom, prénom, adresse, cp, ville' : 'Indiquez : forme, dénomination, capital, siège(adresse, cp, ville), RCS(ville et N°)';
                            sas.schema[5].children[2].label = val === 'physique' ? 'Indiquez : nom, prénom, adresse, cp, ville' : 'Indiquez : forme, dénomination, capital, siège(adresse, cp, ville), RCS(ville et N°)';
                        }
                    }
                }, 'hidden'),
                newInput(def.indiquez_1, '_required', {label: 'Indiquez : nom, prénom, adresse, cp, ville'}, 'hidden'),
                newInput(def.typeSuppleant, '_required', {
                    on: {
                        input: (val) => {
                            sas.schema[5].children[4] = val === 'aucun' ? hidden(sas.schema[5].children[4]) : visible(sas.schema[5].children[4]);
                            sas.schema[5].children[4].label = val === 'physique' ? 'Indiquez : nom, prénom, adresse, cp, ville' :
                                val === 'moral' ?  'Indiquez : forme, dénomination, capital, siège(adresse, cp, ville), RCS(ville et N°)' : '';
                        }
                    }
                }, 'hidden'),
                newInput(def.indiquez_2,'_required', {label: 'Indiquez : nom, prénom, adresse, cp, ville'}, 'hidden')
            ]
        },
        {
            //6
            name: "admissionAssembleGroup",
            label: "Admission aux assemblées",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typeAdmission, '_required', {
                    on: {
                        input: (val) => {
                            sas.schema[6].children[1] = val === 'autre' ? visible(sas.schema[6].children[1]) : hidden(sas.schema[6].children[1]);
                        }
                    }
                }),
                newInput(def.autre, '', {}, 'hidden')
            ]
        },
        {
            //7
            name: "cessionTransmissionActionGroup",
            label: "Cession et transmission des actions",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typeTransmissionAction, '_required', {
                    on: {
                        input: (val) => {
                            sas.schema[7].children[1] = val === 'autre' ? visible(sas.schema[7].children[1]) : hidden(sas.schema[7].children[1]);
                        }
                    }
                }),
                newInput(def.autre, '', {}, 'hidden')
            ]
        }
    ]
};